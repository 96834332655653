function projectSort (a, b) {
    if (a.name < b.name) {
        return -1;
    }
    if (a.name > b.name) {
        return 1;
    }
    return 0;
}

function viewSort (a, b) {
    if (a.isFavorite && !b.isFavorite) {
        return -1;
    }
    if (b.isFavorite && !a.isFavorite) {
        return 1;
    }

    if (a.name < b.name) {
        return -1;
    }
    if (a.name > b.name) {
        return 1;
    }
    return 0;
}

function miracleGrow(treeData, key, parentKey) {
    var keys = [];
    treeData.map(function(x){
        x.children = x.children || [];
        keys.push(x[key]);
    });
    var roots = treeData.filter(function(x){return keys.indexOf(x[parentKey])==-1});
    var nodes = [];
    roots.map(function(x){nodes.push(x)});
    while(nodes.length > 0)
    {
        var node = nodes.pop();
        var children = treeData.filter(function(x){return x[parentKey] == node[key]});
        children.map(function(x){
            node.children.push(x);
            nodes.push(x)
        });
    }
    if (roots.length==1) return roots[0];
    return roots;
}

export {
    projectSort,
    viewSort,
    miracleGrow
};