<template>
    <div class="z-nav-item">
        <button class="btn" v-bind:class="'btn-'+variant" v-on:click="resume()" v-if="viz && isPaused"><b-icon-play></b-icon-play></button>
        <button class="btn" v-bind:class="'btn-'+variant" v-on:click="pause()" v-else-if="viz"><b-icon-pause></b-icon-pause></button>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import store from '../store/store';
import { BIconPause, BIconPlay } from 'bootstrap-vue';
/**
 * Component displays button that pauses or resume Tableau's automatic updates.
 * @tag z-nav-play-pause-button
 */
export default {
    name:'NavPlayPauseButton',
    store,
    components: {
        BIconPause,
        BIconPlay,
    },
    props: {
        /**
         * Bootstrap button variant.
         * @example variant="primary"
         * @values primary, secondary, success, danger, warning, info, light, dark, link
         */
        variant: {
            type: String,
            default: 'primary'
        }
    },
    computed: {
        ...mapState('viz', [
            'viz',
            'isPaused'
        ]),
    },
    methods: {
        ...mapActions('viz', [
            'pause',
            'resume',
        ]),
    },
};
</script>

<style>
</style>
