<template>
  <section class="bg-white content-wrapper">

    <section v-if="!loadingViews && views && views.length < 1" id="no-views">
        <div class="container mt-5 text-center">
            <h5>No views found on the Tableau Server.
              Please ensure your user has permission to see the views.</h5>
        </div>
    </section>

    <div id="loader" v-if="loadingViz">
        <div class="sk-cube-grid">
            <div class="sk-cube sk-cube1"></div>
            <div class="sk-cube sk-cube2"></div>
            <div class="sk-cube sk-cube3"></div>
            <div class="sk-cube sk-cube4"></div>
            <div class="sk-cube sk-cube5"></div>
            <div class="sk-cube sk-cube6"></div>
            <div class="sk-cube sk-cube7"></div>
            <div class="sk-cube sk-cube8"></div>
            <div class="sk-cube sk-cube9"></div>
        </div>
    </div>
    <div class="viz-wrapper" v-bind:class="{centered: alignment === 'center', hidden: loadingViz}" id="viz-wrapper">
        <div id="vizContainer" ref="vizContainer" v-bind:class="{fullscreen: isFullscreen}"></div>
    </div>
  </section>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import store from '../store/store';

let viz = null;
/**
 * Component uses TableauJS API to render Dashboards.
 * @tag z-tableau-viz
 */
export default {
    name:'TableauViz',
    store,
    props: {
        /**
         * Tableau's Dashboard Url that will be use as initial value.
         * @example initial-viz="https://tableau.server.com/dashboard_url"
         * @deprecated
         */
        initialViz: {
            type: String,
            default: null,
        },
        /**
         * Tableau's Dashboard Url that will be use as initial value.
         * @example view="https://tableau.server.com/dashboard_url"
         */
        view: {
            type: String,
            default: null
        },
        /**
         * Passes this values to TableauJS Viz <a href="https://help.tableau.com/current/api/js_api/en-us/JavaScriptAPI/js_api_ref.htm#vizcreateoptions_record" target="_blank">options</a>
         * @example hide-tabs="true"
         */
        hideTabs:{
            type: Boolean,
            default:true
        },
        /**
         * Passes this values to TableauJS Viz <a href="https://help.tableau.com/current/api/js_api/en-us/JavaScriptAPI/js_api_ref.htm#vizcreateoptions_record" target="_blank">options</a>
         * @example hide-toolbar="true"
         */
        hideToolbar: {
            type: Boolean,
            default:true
        },
        /**
         * Passes this values to TableauJS Viz <a href="https://help.tableau.com/current/api/js_api/en-us/JavaScriptAPI/js_api_ref.htm#vizcreateoptions_record" target="_blank">options</a>
         * @example alignment="left"
         * @values left, center
         */
        alignment: {
            type: String,
            default: 'left'
        }
    },
    data() {
        return {
            loadingViz: false
        };
    },
    created() {
        if (this.initialViz) {
            console.warn(`[TableauViz] Property "initial-viz" is deprecated and will be removed in next major release. Use "view" instead.`);
        }
        this.loadFilters();

        if (!this.view && !this.initialViz) {
            this.setRequestedView();
        } else {
            this.setVizView();
        }
    },
    computed: {
        ...mapState('viz',[
            'filters'
        ]),
        ...mapState('views', {
            loadingViews: (state) => state.loadingViews,
            views: (state) => state.views,
            storeView: (state) => state.view,
        }),
        ...mapState({
            isFullscreen: (state) => state.isFullscreen,
        }),
        vizUrl(){
            return this.view || this.initialViz || this.storeView?.url
        }
    },
    methods: {
        ...mapActions('viz',[
            'vizLoaded',
            'loadFilters'
        ]),
        ...mapActions('views',[
            'getRequestedView',
            'setView'
        ]),
        setVizView: function () {
            this.scrollToView();
            const options = {
                hideTabs: this.hideTabs,
                hideToolbar: this.hideToolbar,
                onFirstInteractive: () => {
                    this.loadingViz = false;
                    this.vizLoaded(viz);
                    this.setVizSize();
                },
                ...this.filters,
            };

            if (viz) {
                viz.dispose();
            }

            window.setTimeout(() => {
                viz = new window.tableau.Viz(this.$refs.vizContainer, this.vizUrl, options);
            }, 1);
        },
        setVizSize () {
            const containerEl = this.$refs.vizContainer;
            let parentWidth = containerEl.parentElement.offsetWidth;
            let parentHeight = containerEl.parentElement.offsetHeight;
            let newWidth = parentWidth;
            let newHeight = parentHeight;

            let sheet = viz.getWorkbook().getActiveSheet();
            let size = sheet.getSize();

            // console.debug('Sizing dashboard "' + sheet.getName() + '" as ' + size.behavior);

            switch (size.behavior) {
                case window.tableau.SheetSizeBehavior.AUTOMATIC:
                    // Leave width as is
                    // Set height as 4:3 aspect ratio of width
                    newHeight = parentWidth * 3 / 4;
                    break;
                case window.tableau.SheetSizeBehavior.EXACTLY:
                    // Set to exact size.
                    newWidth = size.minSize.width;
                    newHeight = size.minSize.height;
                    break;
                case window.tableau.SheetSizeBehavior.ATMOST:
                    if (parentWidth > size.maxSize.width) {
                        // Set to maxSize.width and height
                        newWidth = size.maxSize.width;
                        newHeight = size.maxSize.height;
                    } else {
                        // Leave width as is
                        // Set height to match aspect ratio
                        newHeight = parentWidth / size.maxSize.width * size.maxSize.height;
                    }
                    break;
                case window.tableau.SheetSizeBehavior.RANGE:
                    // Make sure size is within range.
                    // Otherwise do same as ATLEAST or ATMOST
                    if (parentWidth < size.minSize.width) {
                        newWidth = size.minSize.width;
                    } else if (parentWidth > size.maxSize.width) {
                        newWidth = size.maxSize.width;
                    }
                    if (parentHeight < size.minSize.height) {
                        newHeight = size.minSize.height;
                    } else if (parentHeight > size.maxSize.height) {
                        newHeight = size.maxSize.height;
                    }
                    break;
                case window.tableau.SheetSizeBehavior.ATLEAST:
                    if (parentWidth < size.minSize.width) {
                        // Set width and height to minSize.width and height
                        newWidth = size.minSize.width;
                        newHeight = size.minSize.height;
                    } else {
                        // Leave width as is
                        // set height to match aspect ratio
                        newHeight = parentWidth * size.minSize.height / size.minSize.width;
                    }
                    break;
            }

            containerEl.style.width = newWidth + 'px';
            containerEl.style.height = newHeight + 'px';
            viz.setFrameSize(newWidth, newHeight);
        },
        scrollToView () {
            window.scrollTo(this.$el.offsetTop, this.$el.offsetLeft);
        },
        setRequestedView () {
            this.getRequestedView({url:window.location.href})
                .then((requestedView) => {
                    if (requestedView) {
                        this.setView(requestedView);
                    }
                });
        }
    },
    watch: {
        'vizUrl': function () {
            this.loadingViz = true;
            this.setVizView();
        },
        '$store.state.views.views': function onViewListChange() {
            // If no requested view set, set first view as initial
            if (this.$store.state.views.views?.length && !this.$store.state.views.view) {
                this.setView(this.$store.state.views.views[0]);
            }
        }
    },
};
</script>

<style>
z-tableau-viz {
    display: block;
}
.viz-wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    transform: translateX(0);
}
.viz-wrapper.centered {
    align-items: center;
    justify-content: center;
}

.viz-wrapper.hidden {
    overflow: hidden;
    transform: translateX(-100vw);
}
.viz-wrapper.hidden>* {
    width: 100vw !important;
    height: calc(100vh - 56px) !important;
}

#vizContainer {
    width: 100vw;
    min-height: calc(100vh - 56px) !important;
    position: relative;
    -webkit-transition: width .4s ease-in-out;
    -moz-transition: width .4s ease-in-out;
    -o-transition: width .4s ease-in-out;
    transition: width .4s ease-in-out;

    -webkit-transition: height .4s ease-in-out;
    -moz-transition: height .4s ease-in-out;
    -o-transition: height .4s ease-in-out;
    transition: height .4s ease-in-out;
}

#vizContainer iframe {
    margin: 0 auto;
}
#vizContainer.fullscreen:fullscreen {
    width: 100vw !important;
    height: 100vh !important;
    overflow: auto !important;
    background-color: #fff !important;
    transition: none !important;
}
/*
 * Zuar loader
 */
#loader {
    position: absolute;
    display: flex;
    width: 100%;
    height: 100%;
    min-height: 300px;
    justify-content: center;
    align-items: center;
}

.sk-cube-grid {
  width: 40px;
  height: 40px;
  margin: 100px auto;
}

.sk-cube-grid .sk-cube {
  width: 33%;
  height: 33%;
  background-color: var(--bs-primary);
  float: left;
  -webkit-animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
          animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
}

.sk-cube-grid .sk-cube1 {
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s; }
.sk-cube-grid .sk-cube2 {
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s; }
.sk-cube-grid .sk-cube3 {
  -webkit-animation-delay: 0.4s;
          animation-delay: 0.4s; }
.sk-cube-grid .sk-cube4 {
  -webkit-animation-delay: 0.1s;
          animation-delay: 0.1s; }
.sk-cube-grid .sk-cube5 {
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s; }
.sk-cube-grid .sk-cube6 {
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s; }
.sk-cube-grid .sk-cube7 {
  -webkit-animation-delay: 0s;
          animation-delay: 0s; }
.sk-cube-grid .sk-cube8 {
  -webkit-animation-delay: 0.1s;
          animation-delay: 0.1s; }
.sk-cube-grid .sk-cube9 {
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s; }

@-webkit-keyframes sk-cubeGridScaleDelay {
  0%, 70%, 100% {
    -webkit-transform: scale3D(1, 1, 1);
            transform: scale3D(1, 1, 1);
  } 35% {
    -webkit-transform: scale3D(0, 0, 1);
            transform: scale3D(0, 0, 1);
  }
}

@keyframes sk-cubeGridScaleDelay {
  0%, 70%, 100% {
    -webkit-transform: scale3D(1, 1, 1);
            transform: scale3D(1, 1, 1);
  } 35% {
    -webkit-transform: scale3D(0, 0, 1);
            transform: scale3D(0, 0, 1);
  }
}

</style>
