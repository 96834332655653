import * as api from '../../services/api.service';

const state = () => ({
    loginTemplate: '',
    loginTemplates: [],
    appTemplate: '',
    appTemplates: [],
    templateScreenshots: {},

    templateRepo: 'zuarbase/rapid-portal-templates', // Default repo
    templateBranch: null,
});

const mutations = {
    loginTemplate (state, template) {
        state.loginTemplate = template;
    },
    loginTemplates (state, templates) {
        state.loginTemplates = templates;
    },
    appTemplate (state, template) {
        state.appTemplate = template;
    },
    appTemplates (state, templates) {
        state.appTemplates = templates;
    },
    templateScreenshots(state, screenshots){
        state.templateScreenshots = screenshots;
    },
    templateBranch (state, branch) {
        state.templateBranch = branch || state.templateBranch;
    },
    templateRepo (state, repo) {
        state.templateRepo = repo || state.templateRepo;
    },
};

const getters = {};

const actions = {
    loadAppTemplates ({commit, state}) {
        return api.getAppTemplates({
            repo: state.templateRepo,
            branch: state.templateBranch
        })
            .then((templates) => {
                if (document.querySelector('meta[name="zwaf:template"]')?.content === 'custom') {
                    templates.push({
                        name: 'custom',
                        sha: 'app'
                    });
                }
                commit('appTemplates', templates);
            });
    },
    loadLoginTemplates ({commit, state}) {
        return new Promise((res, rej) => {
            return api.getLoginTemplates({
                repo: state.templateRepo,
                branch: state.templateBranch
            }).then((templates) => {
                return api.getLocalFile('/login.html').then(fileContents => {
                    const parser = new DOMParser();
                    let doc = parser.parseFromString(fileContents, 'text/html');
                    let metaEl = doc.querySelector('meta[name="zwaf:template"]');
                    if (metaEl.getAttribute('content') === 'custom') {
                        templates.push({
                            name: 'custom',
                            sha: 'login'
                        });
                    }
                    commit('loginTemplates', templates);
                    res(templates);
                });
            })
                .catch(rej);
        });
    },
    loadTemplateScreenshots ({commit, state}) {
        api.getTemplateScreenshots({
            repo: state.templateRepo,
        })
            .then(screenshots => {
                commit('templateScreenshots', screenshots);
            });
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};