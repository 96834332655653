<template>
    <div class="z-nav-item">
        <b-dropdown right v-if="viz" v-bind:variant="variant">
            <template #button-content>
                <b-icon-download data-toggle="dropdown"></b-icon-download>
            </template>
            <b-dropdown-item v-if="isItemVisible('crosstabToCsv')" v-on:click="downloadCrossTabToCSV()" test-id="crosstabToCsv">CrossTab to CSV</b-dropdown-item>
            <b-dropdown-item v-if="isItemVisible('crosstabToExcel')" v-on:click="downloadCrossTabToExcel()" test-id="crosstabToExcel">CrossTab to Excel</b-dropdown-item>
            <b-dropdown-item v-if="isItemVisible('data')" v-on:click="downloadData()" test-id="data">Data</b-dropdown-item>
            <b-dropdown-item v-if="isItemVisible('image')" v-on:click="downloadImage()" test-id="image">Image</b-dropdown-item>
            <b-dropdown-item v-if="isItemVisible('pdf')" v-on:click="downloadPdf()" test-id="pdf">PDF</b-dropdown-item>
            <b-dropdown-item v-if="isItemVisible('powerpoint')" v-on:click="downloadPowerPoint()" test-id="powerpoint">PowerPoint</b-dropdown-item>
        </b-dropdown>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import store from '../store/store';
import { BDropdown, BDropdownItem, BIconDownload } from 'bootstrap-vue';
/**
 * Component displays button with dropdown that contain multiple download options.
 * @tag z-nav-download-button
 */
export default {
    name:'NavDownloadButton',
    store,
    components: {
        BDropdown,
        BDropdownItem,
        BIconDownload,
    },
    props: {
        /**
         * Bootstrap button variant.
         * @example variant="primary"
         * @values primary, secondary, success, danger, warning, info, light, dark, link
         */
        variant: {
            type: String,
            default: 'primary'
        },
        /**
         * Should "CrossTab to CSV" item be visible 
         * @example crosstab-to-csv="false"
         */
        crosstabToCsv:{
            type:Boolean,
            default:true,
        },
        /**
         * Should "CrossTab to Excel" item be visible 
         * @example crosstab-to-excel="false"
         */
        crosstabToExcel:{
            type:Boolean,
            default:true,
        },
        /**
         * Should "Data" item be visible 
         * @example data="false"
         */
        data:{
            type:Boolean,
            default:true,
        },
        /**
         * Should "Image" item be visible 
         * @example image="false"
         */
        image:{
            type:Boolean,
            default:true,
        },
        /**
         * Should "PDF" item be visible 
         * @example pdf="false"
         */
        pdf:{
            type:Boolean,
            default:true,
        },
        /**
         * Should "PowerPoint" item be visible 
         * @example powerpoint="false"
         */
        powerpoint:{
            type:Boolean,
            default:true,
        },
    },
    computed: {
        ...mapState('viz', ['viz'])
    },
    methods: {
        ...mapActions('viz', [
            'downloadPdf',
            'downloadImage',
            'downloadData',
            'downloadCrossTabToCSV',
            'downloadCrossTabToExcel',
            'downloadPowerPoint'
        ]),
        isItemVisible(item) {
            return this[item];
        }
    },
};
</script>

<style>
.b-dropdown-text {
     margin-bottom: 0;
}
</style>
