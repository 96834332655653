<template>
    <div class="z-nav-item">
        <button class="btn" v-bind:class="'btn-'+variant" v-on:click="redo()" v-if="viz"><b-icon-arrow-right></b-icon-arrow-right></button>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import store from '../store/store';
import { BIconArrowRight } from 'bootstrap-vue';
/**
 * Component displays button that redo Tableau's action.
 * @tag z-nav-redo-button
 */
export default {
    name:'NavRedoButton',
    store,
    components: {
        BIconArrowRight,
    },
    props: {
         /**
         * Bootstrap button variant.
         * @example variant="primary"
         * @values primary, secondary, success, danger, warning, info, light, dark, link
         */
        variant: {
            type: String,
            default: 'primary'
        }
    },
    computed: {
        ...mapState('viz', [ 'viz' ]),

    },
    methods: {
        ...mapActions('viz', [
            'redo',
        ]),
    },
};
</script>

<style>
</style>
