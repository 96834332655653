const state = () => ({
    viz: null,
    filters: null, // Object
    isPaused: false,
});

const mutations = {
    viz (state, viz) {
        state.viz = viz;
    },
    filters (state, filters) {
        state.filters = filters;
    },
    isPaused (state, isPaused) {
        state.isPaused = isPaused;
    },
};

const getters = {};

const actions = {
    vizLoaded ({commit, dispatch}, viz) {
        viz.addEventListener(window.tableau.TableauEventName.FILTER_CHANGE, function(event) {
            event.getFilterAsync().then(function(filter) {
                dispatch('tableauFilterChange', filter);
            });
        });

        viz.addEventListener(window.tableau.TableauEventName.PARAMETER_VALUE_CHANGE, function(event) {
            event.getParameterAsync().then(function(param) {
                dispatch('tableauParamChange', param);
            });
        });
        commit('viz', viz);
    },
    loadFilters ({commit}) {
        const params = new URLSearchParams(window.location.search);
        const filters = {};// {name:{type,values}},

        for (const param of params.entries()) {
            const key = param[0]; const
                value = param[1];
            if (Object.prototype.hasOwnProperty.call(filters, key)) {
                filters[key].push(value);
            } else {
                filters[key] = [value];
            }
        }
        commit('filters', filters);
    },
    // Tableau API
    downloadPdf ({state}) {
        state.viz.showExportPDFDialog();
    },
    downloadImage ({state}) {
        state.viz.showExportImageDialog();
    },
    downloadData ({state}) {
        state.viz.showExportDataDialog();
    },
    downloadCrossTabToCSV ({state}) {
        state.viz.showExportCrossTabDialog();
    },
    downloadCrossTabToExcel ({state}) {
        state.viz.exportCrossTabToExcel();
    },
    downloadPowerPoint ({state}) {
        state.viz.showExportPowerPointDialog();
    },
    showDownloadDialog ({state}) {
        state.viz.showDownloadDialog();
    },
    pause ({commit, state}) {
        state.viz.pauseAutomaticUpdatesAsync();
        commit('isPaused', true);
    },
    resume ({commit, state}) {
        state.viz.resumeAutomaticUpdatesAsync();
        commit('isPaused', false);
    },
    refresh ({state}) {
        state.viz.refreshDataAsync();
    },
    revert ({state}) {
        state.viz.revertAllAsync();
    },
    redo ({state}) {
        state.viz.redoAsync();
    },
    undo ({state}) {
        state.viz.undoAsync();
    },
    tableauFilterChange ({ dispatch }, filter) {
        let name = filter.getFieldName(),
            filterType = filter.getFilterType(),
            paramsToApply = {}; //{name:[values]}

        switch (filterType) {
            case window.tableau.FilterType.CATEGORICAL: {
                if (filter.getIsAllSelected()) {
                    paramsToApply[name] = [];
                } else {
                    paramsToApply[name] = filter.getAppliedValues().map(x => x.value);
                }
                break;
            }
        }
        dispatch('updateQueryString', paramsToApply);
    },
    tableauParamChange ({ dispatch }, param) {
        let name = param.getName(),
            value = param.getCurrentValue(),
            paramsToApply = {}; //{name:[values]}
        paramsToApply[name] = [value.value]; //{name:[values]}

        dispatch('updateQueryString', paramsToApply);
    },
    updateQueryString({ }, paramsToApply) {
        let params = new URLSearchParams(window.location.search);
        Object.keys(paramsToApply).forEach(paramName => {
            if (params.has(paramName)) {
                params.delete(paramName)
            }
            for (const entery of paramsToApply[paramName]) {
                params.append(paramName, entery);
            }
        })
        let querysString = params.toString()
        let newUrl = `${window.location.pathname}${querysString.length ? `?${querysString}` : ''}`;
        history.pushState({state: 'filtering'}, 'Filter', newUrl);
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};