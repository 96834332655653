<template>
    <div class="z-nav-item">
        <button class="btn" v-bind:class="'btn-'+variant" v-on:click="refresh()" v-if="viz"><b-icon-arrow-repeat></b-icon-arrow-repeat></button>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import store from '../store/store';
import { BIconArrowRepeat } from 'bootstrap-vue';
/**
 * Component displays button that refresh Tableau Viz data. 
 * @tag z-nav-refresh-button
 */
export default {
    name:'NavRefreshButton',
    store,
    components: {
        BIconArrowRepeat,
    },
    props: {
        /**
         * Bootstrap button variant.
         * @example variant="primary"
         * @values primary, secondary, success, danger, warning, info, light, dark, link
         */
        variant: {
            type: String,
            default: 'primary'
        }
    },
    computed: {
        ...mapState('viz', [ 'viz' ]),
    },
    methods: {
        ...mapActions('viz', [
            'refresh',
        ]),
    },
};
</script>

<style>
</style>
